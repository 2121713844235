<template>
  <base-layout>
    <ion-searchbar show-cancel-button="focus" @ionChange="filter(search)" :value="search" placeholder="Suche"
                   @ionInput="search = $event.target.value;"></ion-searchbar>

    <ion-content class="has-header has-subheader">

      <div class="wcpt-person-list ">
        <div v-for="person in filteredPersons.slice(0,limit)" :key="person.id">
          <router-link
              :to="'persons/' + person.id"
              class="wcpt-person-item shadow p-3 mb-5 bg-body rounded"
              v-if="!person.isLetter"
          >
            <div v-if="!person.vitae_picture">

              <div class="wcpt-person-default-avatar" v-if="!person.vitae_picture">
              <img src="@/assets/user.png" v-if="!person.vitae_picture" style="object-fit:cover"/>
        
              <div class="avatar-title">
                <div>
                  <strong>{{ person.display_name }}</strong>
                  <p>{{ person.city }} ({{person.country}})</p>
                </div>
                <small>{{ person.function }}</small>
              </div>
            </div>

            </div>
            <div class="wcpt-person-avatar" v-if="person.vitae_picture">
              <img src="@/assets/icon/avatar.png" v-if="!person.vitae_picture" style="object-fit:cover"/>
              <img
                  :src="'https://fileserver-188ab.kxcdn.com/file/'+ person.vitae_picture.split('/')[4]"
                  onerror="this.src = '@/assets/user.png'"
                  style="object-fit:cover"
                  v-if="person.vitae_picture"
              />
              <div class="avatar-title">
                <div>
                  <strong>{{ person.display_name }}</strong>
                  <p>{{ person.city }} ({{ person.country }})</p>
                </div>
                <small>{{ person.function }}</small>
              </div>
            </div>
          </router-link>
          <div class="web-person-divider" v-if="person.isLetter" style="width:100vw;padding-left: 20px">
            <h1>{{ person.letter }}</h1>
          </div>
        </div>
      </div>

      <ion-infinite-scroll
          @ionInfinite="increaseLimit($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
            loading-spinner="bubbles"
            loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSearchbar, IonInfiniteScroll, IonInfiniteScrollContent} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions} from "vuex";

export default defineComponent({
  name: "Home",
  data() {
    return {
      persons: [],
      search: "",
      filtered: [],
      limit:50,
      letters:{}
    };
  },
  components: {
    IonContent,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  methods: {
    ...mapActions("persons", ["getAllPersons"]),
    filter(term) {
      if (term.length > 0) {

        this.filtered = this.persons.filter(
            person => Object.keys(person).some(k => {
              return (person[k] && typeof person[k] == 'string' ? person[k].toLowerCase().includes(term.toLowerCase()) : '')
            })
        ).sort( ( a, b) => {
          return a.last_name.localeCompare(b.last_name);
        });
      } else {
        this.filtered = [];
      }
    },
    increaseLimit(ev){
      setTimeout(() => {
        this.limit +=50;
        console.log('Loaded data');
        ev.target.complete();

        if (this.filteredPersons.length <= this.limit) {
          ev.target.disabled = true;
        }
      }, 500);
    },

    groupList(list, selector){
      let letters = [];
      let currentCharCode = 'A'.charCodeAt(0) - 100;
      let sortedList = [];

      function addLetter(code) {
        let letter = String.fromCharCode(code);
        sortedList.push({
          isLetter: true,
          letter: letter
        });
        letters.push(letter);
      }

      (list || []).forEach(function (item) {
        if (item[selector]) {
          let itemCharCode = item[selector].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().charCodeAt(0);
          let difference = itemCharCode - currentCharCode;
          if (difference > 0)
            addLetter(itemCharCode);
          currentCharCode = itemCharCode;
          sortedList.push(item);
        }
      });

      return sortedList;
    }

  },
  computed: {
    filteredPersons: function () {
      let returnList = this.filtered.length ? this.filtered: this.persons;
      return this.groupList(returnList, 'last_name');
    }
  },
  async created() {
    this.persons = (await this.getAllPersons()).sort( ( a, b) => {
      if(a.last_name){ //eine Person hatte keinen Vor- oder Nachnamen, ich habe ein if angegeben, um den undefined zu vermeiden
      return a.last_name.localeCompare(b.last_name);
      }
    });
    this.persons = this.groupList(this.persons, 'last_name');

  }
});
</script>
<style lang="scss">
</style>
